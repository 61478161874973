* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.section__padding {
  padding: 3rem 6rem;
}
