.app__carousel {
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.app__carousel-image {
    width: 300px;
    border-radius: 3px;
}

.app__carousel-left_arrow {
    position: absolute;
    top: 50%;
    left: 140px;
    font-size: 1.5rem;
    color: var(--primary-color);
    z-index: 10;
    cursor: pointer;
    user-select: none;
    transform: translate(0, -50%);
}

.app__carousel-right_arrow {
    position: absolute;
    top: 50%;
    right: 140px;
    font-size: 1.5rem;
    color: var(--primary-color);
    z-index: 10;
    cursor: pointer;
    user-select: none;
    transform: translate(0, -50%);
}

.app__carousel-slide {
    opacity: 0.6;
    transition-duration: 1s ease-in-out;
}
  
.app__carousel-slide_active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.03);
}

.app__carousel-indicator_container {
    margin-top: 18px;
}

.app__carousel-indicator_btn {
    width: 8px;
    height: 8px;
    border: none;
    background-color: var(--primary-color);
    opacity: 0.5;
    margin: 0.2em;
    border-radius: 50%;
    cursor: pointer;
}

.active {
    opacity: 1;
}

@media screen and (max-width: 810px) {
    .app__carousel {
        height: 400px;
    }

    .app__carousel-image {
        width: 250px;
}

.app__carousel-left_arrow {
    left: -50px;
}

.app__carousel-right_arrow {
    right: -50px;
}

}

@media screen and (max-width: 376px) {
    .app__carousel-left_arrow {
        left: -40px;
    }
    
    .app__carousel-right_arrow {
        right: -40px;
    }
}

@media screen and (max-width: 321px) {
    .app__carousel-left_arrow {
        left: -30px;
        font-size: 1rem;
    }
    
    .app__carousel-right_arrow {
        right: -30px;
        font-size: 1rem;
    }
}