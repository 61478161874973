.app__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    padding: 0 4rem;
    background-color: var(--secondary-color);
    height: 100px;
    -webkit-box-shadow: 0px 5px 8px -7px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 5px 8px -7px rgba(0, 0, 0, 1);
    box-shadow: 0px 5px 8px -7px rgba(0, 0, 0, 1);  
}

a {
    text-decoration: none;
    color: var(--text-color);
}

.app__navbar-logo {
    height: 100px;
}

.app__navbar-menu {
    width: 70%;
    font-family: var(--primary-font);
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
}

.app__navbar-menu_list {
    width: 100%;

    display: flex;
    justify-content: flex-end; 
    align-items: center;
    list-style: none;
}

.app__navbar-menu_list-element {
    margin: 0 2.5rem; 
    cursor: pointer;
    position: relative;
    transition: .5s;
}   

.app__navbar-menu_list-element:hover {
    color: var(--primary-color);
}

.app__navbar-menu_list-element::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--primary-color);
    position: absolute;
    bottom: -10px;
    left: 0;
    transition: transform .5s ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
}

.app__navbar-menu_list-element:hover::after {
    transform: scaleX(1);
}

.app__navbar-menu_icon {
    /* width: 50px;
    height: 50px; */
    display: flex;
    flex-direction: column;
    display: none; 
}

.app__navbar-menu_icon span{
    width: 18px;
    height: 1px;
    border-radius: 3px;
    background-color: #000;
    margin: 3px 0;
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-toggle {
    display: none;
}

.app__navbar-smallscreen_menu-list {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: fixed;
    z-index: 99;

    list-style: none;
    padding: .5rem 0;
    background-color: var(--secondary-color);
    -webkit-box-shadow: 0px 5px 8px -7px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 5px 8px -7px rgba(0, 0, 0, 1);
    box-shadow: 0px 5px 8px -7px rgba(0, 0, 0, 1);  
    
}

.navbar-link {
    text-decoration: none;
    color: var(--text-color);
}

.navbar-link:hover {
    color: var(--primary-color);
}

.app__navbar-smallscreen_menu-list_element {
    font-family: var(--primary-font);
    font-size: 16px;

    margin: 1rem 0;
}

@media screen and (max-width: 1024px) {

    .app__navbar {
        padding: 0 3rem;
    }

    .app__navbar-menu {
        font-size: 13px;
    }

    .app__navbar-menu_list-element {
        margin: 0 2rem; 

    }   
}

@media screen and (max-width: 810px) {

    .app__navbar {
        z-index: 999;
    }

    .app__navbar-menu {
        display: none;
    }

    .app__navbar-smallscreen {
        display: block;
    }

    .app__navbar-toggle {
        display: block;
    }
}

@media screen and (max-width: 426px) {
    .app__navbar-smallscreen_menu-list_element {
        font-size: 15px;
        margin: 0.9rem 0;
    }
}

@media screen and (max-width: 376px) {
    .app__navbar {
        padding: 0 2rem;
    }

    .app__navbar-smallscreen_menu-list_element {
        font-size: 14px;
        margin: 0.8rem 0;
    }
}


@media screen and (max-width: 321px) {
    .app__navbar {
        padding: 0 1.5rem;
    }
    .app__navbar-smallscreen_menu-list_element {
        font-size: 13px;
        margin: 0.7rem 0;
    }
}