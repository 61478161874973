.app__about {
    background-color: var(--secondary-color);
    width: 100%;
    padding: 2rem 0 3rem 6rem;  
    position: relative;
    overflow: hidden;
}


.app__about-header {
    font-family: var(--primary-font);
    color: var(--text-color);
    position: relative;
    font-size: 46px;
}


.app__about-underscore {
    width: 200px;
    height: 2px;
    background-color: var(--primary-color);
    margin: 10px 0;

}

.app__about-content {
    font-family: var(--primary-font);
    color: var(--text-color);
    display: flex;
    width: 100%;
    text-align: left;
    font-size: 15px;
    height: 100%;
}

.app__about-text_container {
    width: 50%;
}

.app__about-content_text:first-child {
    margin-top: 30px;
}

.app__about-content_text {
    margin: 0.4rem 0;
    line-height: 27px;
}

.app__about-btn_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__about-content_btn {
    cursor: pointer;
    padding: 12px;
    margin: 20px 0;
    font-family: var(--primary-font);
    border-style: none;
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    -webkit-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);  
}

.app__about-image_container {
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    overflow: hidden;
}

.app__about-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 20%;
}

.client-message {
    font-size: 20px;
    margin-top: 20px;
}

.app__about-content_btn-link {
    text-decoration: none;
    color: var(--primary-color);
}

@media screen and (max-width: 810px) {

    .app__about {
        padding: 2rem 0 0 0; 
    }

    .app__about-header_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .app__about-content {
        flex-direction: column;
        text-align: center;
    }

    .app__about-text_container {
        width: 100%;
        padding: 0 3rem;
        margin-bottom: 30px;
    }
    

    .app__about-image_container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .client-message {
        font-size: 18px;
    }
    
}

@media screen and (max-width: 426px) {
    .app__about-text_container {
        padding: 0 1.5rem;
    }


    .app__about-header {
        font-size: 40px;
    }
}