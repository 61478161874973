.app__hero {
    width: 100%;
    height: calc(100vh - 100px);
    position: relative;
    overflow: hidden;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;    
    animation: 2s ease-in-out enter;
    
}

.app__hero-content_image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__hero-logo_image {
    width: 350px;
    margin-top: -80px;
}

.app__hero-content {
    display: flex;
    flex-direction: column;
}

.app__hero-content_text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.app__hero-content_text {
    font-family: var(--primary-font);
    color: #DBD8B8;
    font-size: 46px;
    font-weight: 500;
    position: relative;
}

.app__hero-content_text::after {
    content: '';
    width: 50%;
    height: 1px;
    background-color: var(--primary-color);
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.app__hero-decoration_image-right {
    width: 600px;
    position: absolute;
    bottom: -50px;
    right: -130px;
    color: var(--primary-color);
    transform: rotate(-60deg);
    z-index: -1;
}

.app__hero-decoration_image-left {
    width: 600px;
    position: absolute;
    top: -50px;
    left: -130px;
    color: var(--primary-color);
    transform: rotate(120deg);
    z-index: -1;
}

@media screen and (max-width: 1024px) {
    .app__hero-decoration_image-right, .app__hero-decoration_image-left{
        width: 450px;
    }

    .app__hero-logo_image {
        width: 300px;
    }

    .app__hero-content_text {
        font-size: 40px;
    }    

}

@media screen and (max-width: 810px) {
    .app__hero-decoration_image-right, .app__hero-decoration_image-left{
        width: 400px;
    }

    .app__hero-content_text {
        font-size: 36px;
    }   
}

@media screen and (max-width: 426px) {

    .app__hero-decoration_image-right, .app__hero-decoration_image-left{
        width: 300px;
    }
    .app__hero-logo_image {
        width: 280px;
    }

    .app__hero-content_text {
        font-size: 28px;
    }    

    .app__hero-content_text-container {
        text-align: center;
    }
}

@media screen and (max-width: 376px) {
    .app__hero-logo_image {
        width: 250px;
    }

    .app__hero-content_text {
        font-size: 24px;
    }    

}

@media screen and (max-width: 321px) {
    .app__hero-logo_image {
        width: 230px;
    }

    .app__hero-content_text {
        font-size: 21px;
    }    
}

@keyframes enter {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }