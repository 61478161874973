.app__footer {
    background-color: var(--primary-color);
    padding: 2rem 6rem;
    font-family: var(--primary-font);
    color: var(--secondary-color);
}

.app__footer-heading_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.app__footer_heading{
    position: relative;
    font-size: 46px;
}

.app__footer-underscore {
    width: 200px;
    height: 2px;
    background-color: #DBD8B8;
    margin: 10px 0;
}

.app__footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.top {
    font-size: 17px;
}

.app__footer-address{ 
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.app__footer-inner_address{
    display: flex;
    align-items: flex-start;
}

.app__icon-container {
    padding-top: 2px;
}

.app__footer-inner_phone {
    display: flex;
    align-items: flex-end;
}

.app__footer-social {
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.app__footer-social_element {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 4px 0;
}

.app__footer-inner {
    display: flex; 
    align-items: center;
    justify-content: center ;
}

.app__rights-container {    
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app__footer-rights {
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app__footer-link, .app__footer-address_number{
    text-decoration: none;
    color: var(--secondary-color);
}

.app__footer-link:hover, .app__footer-address_number:hover, .rights-link:hover {
    color: var(--secondary-color);
}

.rights-link {
    margin-left: 3px;
    color: var(--secondary-color);
}

.footer-icon {
    margin-right: 12px;
}



@media screen and (max-width: 426px) {

    .app__footer {
        padding: 2rem 1rem;
    }

    .app__footer_heading {
        font-size: 40px;
    }

    .app__footer-content {
        flex-direction: column;
    }

    .app__footer-social {
        margin-left : 0;
    }

    .app__footer-address {
        margin-right: 0;
        margin-bottom: 30px;
    }

    .app__footer-rights {
        font-size: 13px;
    }
}