.app__offer {
    padding: 3rem 10rem; 
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    font-family: var(--primary-font);
    overflow: hidden;
    position: relative;
}

.app__offer-carousel_container {
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 50%;
    margin-left: 50px;
}

.app__offer-carousel {
    width: 50%;
}

.carousel-control-next, .carousel-control-prev {
    top: 0;
}

.carousel-indicators {
  bottom: 0;
  margin-bottom: 0;
}

.app__offer-text_container {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 50px;
}

.app__offer-heading {
    color: var(--text-color);
    position: relative;
    font-size: 46px;
}

.app__offer-underscore_container {
   width: 100%; 
   display: flex;
   justify-content: flex-start;
}

.app__offer-underscore {
    width: 200px;
    height: 2px;
    background-color: var(--primary-color);
    margin: 10px 0;
}

.app__offer-subheading {
    margin: 10px 0;
    font-size: 22px;
}

p {
    margin: 6px 0;
    font-size: 15px;
}

.app__offer-decoration_image-left {
    position: absolute;
    width: 600px;
    top: -200px;
    right: -80px;
    transform: rotate(-90deg);
    z-index: -1;
}

.app__offer-decoration_image-right {
    position: absolute;
    width: 600px;
    bottom: -240px;
    left: -30px;
    transform: rotate(70deg);
    z-index: -1;
}

@media screen and (max-width: 1025px) {

    .app__offer {
        padding: 3rem 6rem; 
    }

    .carousel-control-next, .carousel-control-prev {
        top: 0;
    }
    
    .carousel-indicators {
      bottom: 0px;
    margin-bottom: 0;
    }

    .app__offer-carousel_container {
        padding: 0;
        width: 65%;
    }
    
    .app__offer-carousel {
        width: 100%;
    }
    

    .app__offer-text_container {
        text-align: center;
        align-items: center;
        justify-content: flex-start;
    }

    .app__offer-decoration_image-left {
        width: 450px;
        top: -160px;
        right: -40px;
        transform: rotate(-98deg);
     }

     .app__offer-decoration_image-right {
        width: 450px;
        bottom: -230px;
        left: -45px;
        transform: rotate(70deg);
    }   

}

@media screen and (max-width: 810px) {
    .app__offer {
        align-items: center;
        padding: 3.5rem;
        justify-content: space-between;
    }

    
    .app__offer-carousel_container {
        padding: 0;
        width: 65%;
    }
    
    .app__offer-carousel {
        width: 100%;
    }
    

    .app__offer-text_container {
        text-align: center;
        align-items: center;
        justify-content: flex-start;
    }

    .app__offer-underscore_container {
        width: 100%; 
        display: flex;
        justify-content: center;
     }


}

@media screen and (max-width: 426px) {
    .app__offer {
        flex-direction: column-reverse;
        padding: 1.5rem 1rem;
    }

    .app__offer-text_container  {
        margin-bottom: 30px;
        margin-right: 0;
    }

    .app__offer-heading {
        font-size: 40px;
    }

    .app__offer-carousel_container {
        padding: 0;
        width: 100%;
        margin: 0;
    }
    
    .app__offer-carousel {
        width: 100%;
    }
    
    .carousel-control-next, .carousel-control-prev {
        top: 0px;
    }
    
    .carousel-indicators {
      bottom: 0px;
    }

    .app__offer-decoration_image-right, .app__offer-decoration_image-left {
        display: none;
    }
}
