.app__wedding-carousel {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.app__wedding-carousel_decoration {
    position: absolute;
    top: -40px;
    left: -110px;
    width: 450px;
    transform: rotate(140deg);
}

.app__wedding-carousel_decoration-two {
    position: absolute;
    top: -140px;
    right: -50px;
    width: 450px;
    transform: rotate(-110deg);
}

.carousel-control-next, .carousel-control-prev {
    top: 0px;
}

.carousel-indicators {
  bottom: 0px;
}

.app__wedding-carousel_heading-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__wedding-carousel_heading {
    color: var(--text-color);
    font-family: var(--primary-font);
    position: relative;
    font-size: 46px;
}

.app__wedding-carousel_underscore {
    width: 300px;
    height: 2px;
    background-color: var(--primary-color);
    margin: 10px 0 30px 0;
}

.carousel {
    width: 60%;
    height: 80%;
}


@media screen and (max-width: 810px) {
    .carousel {
        width: 80%;
        height: 80%;
    }

    .app__wedding-carousel_underscore { 
        margin: 10px 0 50px 0;
    }

    .app__wedding-carousel_decoration {
        top: -60px;
        left: -130px;
        width: 350px;
        transform: rotate(160deg);
    }
    
    .app__wedding-carousel_decoration-two {
        top: -140px;
        right: -50px;
        width: 350px;
        transform: rotate(-125deg);
    }
}

@media screen and (max-width: 426px) {
    .carousel {
        width: 95%;
        height: 80%;
    }

    .app__wedding-carousel_underscore { 
        margin: 10px 0 50px 0;
    }

    .app__wedding-carousel_heading {
        font-size: 40px;
    }
    
    .app__wedding-carousel_underscore {
        width: 240px;
    }

    
}

