.app__form-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--primary-color);
}

.app__form-checkbox_heading {
  font-size: 16px;
  font-weight: 500;
}

.app__form-checkbox_content-container {
  display: flex;
  width: 100%;
}

.app__form-checkbox_flower-container {
  width: 50%;
}

.app__form-checkbox_flower-heading {
  font-size: 15px;
}

.app__form-checkbox_decoration-container {
  width: 50%;
}

.form__choices label{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.choices__text {
  margin-right: 10px;
}

.small {
  font-size: 15px;
}

.long-text{
  width: 55%;
  margin-right: 10px;
}

.form__choices input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form__choices .checkbox {
  height: 17px;
  width: 17px;
  background-color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  border-radius: 3px;
}

.form__choices input:checked ~ .checkbox {
  background-image: url(../../assets/Checkmark.svg);
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 810px) {
  .app__form-checkbox_flower-heading {
    font-size: 13px;
}

.app__form-checkbox{
  padding-bottom: 20px;
}
.app__form-checkbox_heading {
    font-size: 15px;
}

.app__form-checkbox_heading {
    font-size: 15px;
}


.choices__text {
    font-size: 13px;
}

.small{
  font-size: 13px;
}


.long-text{
  margin-right: 25px;
}

.file-container_heading {
    font-size: 15px;
}
}

@media screen and (max-width: 426px) {
  .long-text{
    margin-right: 18px;
  }

}

@media screen and (max-width: 321px) {

}