.app__delivery {
    background-color: var(--primary-color);
    font-family: var(--primary-font);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 2rem 0;
    position: relative;
    height: 100%;
}

.app__delivery-heading {
    color: var(--secondary-color);
    position: relative;
    font-size: 46px;
}

.app__delivery-underscore {
    width: 200px;
    height: 2px;
    background-color: #DBD8B8;
    margin: 10px 0;
}

.app__delivery-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.app__delivery-text {
    color: var(--secondary-color);
}

.app__delivery-text:first-child {
    margin-top: 15px;
}

.app__delivery-btn {
    cursor: pointer;
    padding: 12px;
    margin: 20px 0;
    font-family: var(--primary-font);
    border-style: none;
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    -webkit-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);  
}

.app__delivery-btn_link {
    text-decoration: none;
    color: var(--primary-color);
}

@media screen and (max-width: 810px) {
    .app__delivery {
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 426px) {
    .app__delivery {
        padding: 1rem 1.5rem;
    }

    .app__delivery-heading {
        font-size: 40px;
    }
}