.app__wedding {
    display: flex;
    font-family: var(--primary-font);
    padding: 0 6rem 2rem 6rem;  
    overflow: hidden;
}

.app__wedding-image_container {
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 6rem 2rem 0;
    position: relative;
}

.app__wedding-image_container-inner {
    border: 2px solid #DBD8B8;
    padding: 2rem;
}

.app__wedding-image {
    object-fit: contain;
    width: 280px;
}

.app__wedding-decoration {
    position: absolute;
    top: -30px;
    right: -80px;
    z-index: -1;
    width: 250px;
    transform: rotate(60deg);
}

.two {
    position: absolute;
    width: 250px;
    top: 400px;
    left: -180px;
    transform: rotate(-120deg);
    z-index: -2 ;
}

.app__wedding-text_container {
    width: 70%;
    text-align: center;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app__wedding-heading {
    color: var(--text-color);
    position: relative;
    font-size: 46px;
}

.app__wedding-underscore {
    width: 200px;
    height: 2px;
    background-color: var(--primary-color);
    margin: 10px 0;
}

.app__wedding-para {
    margin: 0.4rem 0;
    line-height: 27px;
}

.app__wedding-btn {
    cursor: pointer;
    padding: 12px;
    margin: 20px 0;
    font-family: var(--primary-font);
    border-style: none;
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    -webkit-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);  
}

.app__wedding-btn a {
    text-decoration: none;
    color: var(--primary-color);
}

@media screen and (max-width: 1024px) {
    .app__wedding-image {
        width: 230px;
    }

    .app__wedding {
        padding: 0 0 2rem 6rem;  
    }
    
    .app__wedding-text_container {
        padding: 2rem 2rem 2rem 0;
        justify-content: flex-end;
    }

    .two {
        top: 350px;
        left: -210px;
    }

    .app__wedding-heading {
        font-size: 42px;
    }

    .app__wedding-para {
        font-size: 13px;
    }
    
}
@media screen and (max-width: 810px) {
    .app__wedding {
        flex-direction: column-reverse;
        padding: 0 3rem 2rem 3rem;  
    }

    .app__wedding-image_container {
        width: 100%;
        padding: 0;
        margin-top: 20px;
    }

    .app__wedding-text_container {
        width: 100%;
        padding: 2rem 0;
    }

    .app__wedding-image {
        width: 280px;
    }

    
    .app__wedding-decoration {
        top: -110px;
        right: 0;
        width: 300px;
    }

    .two {
        width: 300px;
        top: 330px;
        left: -50px;
    }
}

@media screen and (max-width: 426px) {
    .app__wedding {
        padding: 0 1rem 2rem 1rem;  
    }

    .app__wedding-heading {
        font-size: 40px;
    }

    .app__wedding-para {
        font-size: 15px;
    }

    .app__wedding-decoration {
        top: -110px;
        right: -120px;
        width: 250px;
    }

    .two {
        width: 250px;
        top: 310px;
        left: -130px;
    }
}

@media screen and (max-width: 376px) { 
    .app__wedding-image {
        width: 240px;
    }

    .app__wedding-decoration {
        width: 230px;
    }

    .two {
        width: 230px;
    }
}

@media screen and (max-width: 321px) { 
    .app__wedding-image {
        width: 220px;
    }

    .app__wedding-decoration {
        width: 210px;
        top: -90px;
        right: -120px;
    }

    .two {
        width: 210px;
        top: 280px;
        left: -130px; 
    }
}