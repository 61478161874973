.app__form {
    display: flex;
    flex-direction: column;
    padding: 2rem 6rem 3rem 6rem;  
    font-family: var(--primary-font);
}

.app__form-container {
    width: 100%;
    margin-top: 45px;
}

.app__form-heading-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.app__form-heading {
    color: var(--text-color);
    position: relative;
    font-size: 46px;
}

.app__form-heading_underscore {
    width: 200px;
    height: 2px;
    background-color: var(--primary-color);
    margin: 10px 0;
}

.app__form-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.app__form-first_container {
    width: 50%;
}

.app__form-first_container label {
    font-weight: 500;
}

.app__form-second_container {
    width: 50%;
}

.app__form-element {
    display: flex;
    flex-direction: column;
}

.app__form-budget_inner {
    display: flex;
}

.app__form-label {
    margin: 10px 0 6px 0;
}

.primary-input {
    border-style: none;
    border-bottom: 1px solid var(--primary-color);
    width: 70%;
    padding-bottom: 5px;
}

input[type="date"]{
    color: var(--primary-color);
}



input[type="file"]::file-selector-button {
    cursor: pointer;
    padding: 5px 20px;
    margin: 5px 5px 0 0;
    font-size: 14px;
    font-family: var(--primary-font);
    border-style: none;
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    -webkit-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);  
}

.file-input_container {
    display: flex;
    flex-direction: column;
    margin: 10px 0 20px 0;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--primary-color);
}

.file_container-text {
    font-size: 14px;
}

.pinterest {
    color: var(--primary-color);
    text-decoration: none;
}

.file-container_heading {
    font-size: 16px;
    font-weight: 500;
}

.file-inner_container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-top: 15px;
}

.form__consent {
    margin-top: 30px;
}
  
.form__checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 1.375rem;
}
  
.form__checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form__checkbox .checkbox {
    position: absolute;
    top: 4px;
    left: 0;
    height: 17px;
    width: 17px;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    border-radius: 3px;
}
  
  
.form__checkbox input:checked ~ .checkbox {
    background-image: url(../../assets/Checkmark.svg);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
  }

.radio {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--primary-color);
}

.radio-heading {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
}

.form__choices-radio {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
}

.radio-choices__text {
    margin-right: 10px;
}

.form__choices-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.form__choices-radio .radio-span {
    height: 17px;
    width: 17px;
    background-color: var(--secondary-color);
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    cursor: pointer;
}

  
.form__choices-radio input:checked ~ .radio-span {
    background-image: url(../../assets/Checkmark.svg);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
  }

.app__form-btn_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    text-align: center;
}

.app__form-btn {
    cursor: pointer;
    padding: 10px 35px;
    margin: 10px 0;
    font-family: var(--primary-font);
    border-style: none;
    border-radius: 3px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    -webkit-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);  
}

.photos-btn {
    cursor: pointer;
    padding: 12px;
    margin: 20px 0;
    font-family: var(--primary-font);
    border-style: none;
    border: 1px solid var(--primary-color);
    border-radius: 3px;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    -webkit-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    -moz-box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);
    box-shadow: 8px 8px 31px -19px rgba(0, 0, 0, 1);  
}

.pinterest-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
    font-size: 15px;
    font-weight: 500;
}

.currency {
    margin-left: 3px;
}

.error {
    font-size: 11px;
    color: red;
}

.success {
    font-size: 14px;
    color: rgb(110, 183, 0);
}

.photo-link {
    text-decoration: none;
    color: var(--primary-color);

}

.photo-link:hover {
    color: var(--primary-color);
}

@media screen and (max-width: 810px) {
    .app__form {
        padding: 0 3rem 2rem 3rem;
    }

    .app__form-heading-container {
        text-align: center;
    }

    .primary-input {
        font-size: 13px;
    }

    .app__form-label {
        font-size: 15px;
    }

    .file-container_text {
        font-size: 13px;
    }

    .file-inner_container {
        font-size: 13px;
    }

    .radio-heading {
        font-size: 15px;
    }

    .radio-choices__text{
        font-size: 13px;
    }

    .consent__text {
        font-size: 13px;
    }
}

@media screen and (max-width: 426px) {

    .app__form {
        padding: 0 1rem 2rem 1rem;
    }

    .app__form-heading {
        font-size: 40px;
    }

    .app__form-content {
        display: flex;
        flex-direction: column;
    }

    .app__form-first_container {
        width: 100%;
    }

    .app__form-second_container {
        width: 100%;
    }

    .primary-input {
        width: 100%;
    }

    .app__form-btn_container {
        margin-top: 15px;
    }

    .success {
        font-size: 11px;
    }
}

@media screen and (max-width: 376px) {

}